<template>
  <div class="app-page-toolbar">
    <!--    <el-tooltip-->
    <!--      :content="destroyButtonTooltip"-->
    <!--      :disabled="!destroyButtonTooltip"-->
    <!--      v-if="hasPermissionToDestroy"-->
    <!--    >-->
    <!--      <span>-->
    <!--        <el-button-->
    <!--          :disabled="destroyButtonDisabled"-->
    <!--          @click="doDestroyAllSelected"-->
    <!--          icon="el-icon-fa-trash"-->
    <!--          type="primary"-->
    <!--        >-->
    <!--          <app-i18n code="common.destroy"></app-i18n>-->
    <!--        </el-button>-->
    <!--      </span>-->
    <!--    </el-tooltip>-->

<!--      <el-button-->
<!--              @click="doPublish"-->
<!--              icon="el-icon-fa-paper-plane"-->
<!--              type="primary"-->
<!--      >-->
<!--          <app-i18n code="common.publish"></app-i18n>-->
<!--      </el-button>-->


      <router-link
              :to="{ path: '/audit-logs', query: { entityNames: 'scoreOutput' } }"
              v-if="hasPermissionToAuditLogs"
      >
          <el-button icon="el-icon-fa-history">
              <app-i18n code="auditLog.menu"></app-i18n>
          </el-button>
      </router-link>

      <el-button
              v-if="hasAdminRole"
              @click="doPublishAll"
              icon="el-icon-fa-paper-plane"
              type="primary"
              :disabled="saveLoading"
      >
          <app-i18n code="common.publishAll"></app-i18n>
      </el-button>
  </div>
</template>

<script>
import { AuditLogPermissions } from '@/modules/audit-log/audit-log-permissions';
import { mapGetters, mapActions } from 'vuex';
import { ScoreOutputPermissions } from '@/modules/score-output/score-output-permissions';
import { i18n } from '@/i18n';

export default {
  name: 'app-score-output-list-toolbar',

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      hasRows: 'scoreOutput/list/hasRows',
      loading: 'scoreOutput/list/loading',
      saveLoading: 'scoreOutput/list/saveLoading',
      selectedRows: 'scoreOutput/list/selectedRows',
      destroyLoading: 'scoreOutput/destroy/loading',
      hasAdminRole: 'auth/hasAdminRole',
    }),

    hasPermissionToAuditLogs() {
      return new AuditLogPermissions(this.currentUser).read;
    },

    hasPermissionToCreate() {
      return new ScoreOutputPermissions(this.currentUser).create;
    },

    hasPermissionToEdit() {
      return new ScoreOutputPermissions(this.currentUser).edit;
    },

    hasPermissionToImport() {
      return new ScoreOutputPermissions(this.currentUser).import;
    },

    hasPermissionToDestroy() {
      return new ScoreOutputPermissions(this.currentUser)
        .destroy;
    },

    removeButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },

    removeButtonTooltip() {
      return !this.selectedRows.length
        ? i18n('common.mustSelectARow')
        : null;
    },

    enableButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },

    enableButtonTooltip() {
      return !this.selectedRows.length
        ? i18n('common.mustSelectARow')
        : null;
    },

    disableButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },

    disableButtonTooltip() {
      return !this.selectedRows.length
        ? i18n('common.mustSelectARow')
        : null;
    },

    destroyButtonDisabled() {
      return (
        !this.selectedRows.length ||
        this.loading ||
        this.destroyLoading
      );
    },

    destroyButtonTooltip() {
      // if (!this.selectedRows.length || this.loading) {
      //   return i18n('common.mustSelectARow');
      // }

      return null;
    },
  },

  methods: {
    ...mapActions({
      doRemoveAllSelected:
        'scoreOutput/list/doRemoveAllSelected',
      doDisableAllSelected:
        'scoreOutput/list/doDisableAllSelected',
      doEnableAllSelected:
        'scoreOutput/list/doEnableAllSelected',
      doDestroyAll: 'scoreOutput/destroy/doDestroyAll',
      doPublishInScore: 'scoreOutput/form/doPublish',
      doPublishAll: 'scoreOutput/list/doPublishAll',
      doFetch: 'scoreOutput/list/doFetch',
    }),

    async doPublish() {
      await this.doPublishInScore();
      const filter = {};
      await this.doFetch({ filter, keepPagination: true });
    },
    async doDestroyAllSelected() {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        return this.doDestroyAll(
          this.selectedRows.map((item) => item.id),
        );
      } catch (error) {
        // no
      }
    },
  },
};
</script>

<style></style>
