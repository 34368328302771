<template>
  <el-form
    :label-position="labelPosition"
    :label-width="labelWidthFilter"
    :model="model"
    :rules="rules"
    @submit.native.prevent="doFilter"
    class="filter"
    ref="form"
    v-loading="saveLoading"
  >
    <el-row>
      <!--      <el-col :lg="12" :md="16" :sm="24">-->
      <!--        <el-form-item :label="fields.id.label" :prop="fields.id.name">-->
      <!--          <el-input v-model="model[fields.id.name]"/>-->
      <!--        </el-form-item>-->
      <!--      </el-col>-->

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item
          :label="fields.scoreStatus.label"
          :prop="fields.scoreStatus.name"
        >
          <el-select
            placeholder
            v-model="model[fields.scoreStatus.name]"
          >
            <el-option :value="undefined">--</el-option>
            <el-option
              :key="option.id"
              :label="option.label"
              :value="option.id"
              v-for="option in fields.scoreStatus.options"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24"  v-if="canListOtherQEs"   >
        <el-form-item
          :label="fields.teacher.label"
          :prop="fields.teacher.name"
        >
          <app-autocomplete-one-input
            :fetchFn="fetchTeacherACwithLangs"
            v-model="model[fields.teacher.name]"
          ></app-autocomplete-one-input>
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item
          :label="fields.examOutputStr.label"
          :prop="fields.examOutputStr.name"
        >
          <el-input v-model="model[fields.examOutputStr.name]" />
        </el-form-item>
      </el-col>

      <!--      <el-col :lg="12" :md="16" :sm="24">-->
      <!--        <el-form-item :label="fields.examOutput.label" :prop="fields.examOutput.name">-->
      <!--          <app-autocomplete-one-input-->
      <!--                  :fetchFn="fields.examOutput.fetchFn"-->
      <!--                  v-model="model[fields.examOutput.name]"-->
      <!--          ></app-autocomplete-one-input>-->
      <!--        </el-form-item>-->
      <!--      </el-col>-->
    </el-row>

    <div class="filter-buttons">
      <el-button
        :disabled="loading"
        @click="doFilter"
        icon="el-icon-fa-search"
        type="primary"
      >
        <app-i18n code="common.search"></app-i18n>
      </el-button>

      <el-button
        :disabled="loading"
        @click="doResetFilter"
        icon="el-icon-fa-undo"
      >
        <app-i18n code="common.reset"></app-i18n>
      </el-button>
    </div>
  </el-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FilterSchema } from '@/shared/form/filter-schema';
import { ScoreOutputModel } from '@/modules/score-output/score-output-model';
import { IamService } from '../../iam/iam-service';

const { fields } = ScoreOutputModel;

const filterSchema = new FilterSchema([
  fields.id,
  fields.scoreStatus,
  fields.teacher,
  fields.examOutputStr,
]);

export default {
  name: 'app-score-output-list-filter',

  data() {
    return {
      rules: filterSchema.rules(),
      model: {},
      fetchTeacherACwithLangs: null,
    };
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthFilter: 'layout/labelWidthFilter',
      loading: 'scoreOutput/list/loading',
      saveLoading: 'scoreOutput/list/saveLoading',
      filter: 'scoreOutput/list/filter',
      hasAdminRole: 'auth/hasAdminRole',
      canListOtherQEs: 'auth/canListOtherQEs',
      currentUserLangs: 'auth/currentUserLangs'
    }),

    fields() {
      return fields;
    },
  },

  created() {
    //mounted'da calismaz
    this.fetchTeacherACwithLangs = IamService.fetchTeacherACwithLangs(this.currentUserLangs);
  },

  async mounted() {
    this.model = filterSchema.initialValues(
      this.filter,
      this.$route.query,
    );

    return this.doFilter();
  },

  methods: {
    ...mapActions({
      doReset: 'scoreOutput/list/doReset',
      doFetch: 'scoreOutput/list/doFetch',
    }),

    async doResetFilter() {
      this.model = filterSchema.initialValues();
      this.$refs.form.clearValidate();
      return this.doReset();
    },

    async doFilter() {
      try {
        await this.$refs.form.validate();
        this.$refs.form.clearValidate();
      } catch (error) {
        return;
      }

      const filter = filterSchema.cast(this.model);
      return this.doFetch({
        filter,
      });
    },
  },
};
</script>

<style></style>
