<template>
  <div>
    <el-table stripe
      :border="true"
      :data="rows"
      @sort-change="doChangeSort"
      ref="table"
      row-key="id"
      v-loading="loading | saveLoading"
    >
      <el-table-column type="selection" width="55"></el-table-column>

      <el-table-column :label="fields.id.label" :prop="fields.id.name" sortable="custom">
        <template slot-scope="scope">{{ presenter(scope.row, 'id') }}</template>
      </el-table-column>

      <el-table-column align="center" v-if="hasAdminRole" :label="fields.teacher.label" :prop="fields.teacher.name">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p>{{ scope.row.teacher.email }}</p>
            <div slot="reference" class="name-wrapper">
              <app-list-item-relation-to-one
                      :label="fields.teacher.label"
                      :permission="fields.teacher.readPermission"
                      :url="fields.teacher.viewUrl"
                      :value="presenter(scope.row, 'teacher')"
              ></app-list-item-relation-to-one>
            </div>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column align="center"
        :label="fields.scoreStatus.label"
        :prop="fields.scoreStatus.name"
        sortable="custom"
      >
        <template slot-scope="scope">
          {{ presenter(scope.row, 'scoreStatus') }}
        </template>
      </el-table-column>

      <el-table-column :label="fields.examOutput.label" :prop="fields.examOutput.name">
        <template slot-scope="scope">
          <app-list-item-relation-to-one
            :label="fields.examOutput.label"
            :permission="fields.examOutput.readPermission"
            :url="fields.examOutput.viewUrl"
            :value="presenter(scope.row, 'examOutput')"
          ></app-list-item-relation-to-one>
        </template>
      </el-table-column>

      <el-table-column :fixed="isMobile? undefined : 'right'" align="center" width="180">
        <template slot-scope="scope">
          <div class="table-actions">
            <router-link :to="`/score-output/${scope.row.id}`">
              <el-button type="text">
                <app-i18n code="common.view"></app-i18n>
              </el-button>
            </router-link>

            <router-link :to="`/score-output/${scope.row.id}/edit`"
                         v-if="hasPermissionToEdit && scope.row.scoreStatus !== '3'">
              <el-button type="text">
                <app-i18n code="common.edit"></app-i18n>
              </el-button>
            </router-link>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="el-pagination-wrapper">
      <el-pagination
        :current-page="pagination.currentPage || 1"
        :disabled="loading || saveLoading"
        :layout="paginationLayout"
        :total="count"
        @current-change="doChangePaginationCurrentPage"
        @size-change="doChangePaginationPageSize"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { ScoreOutputModel } from '@/modules/score-output/score-output-model';
import { mapGetters, mapActions } from 'vuex';
import { ScoreOutputPermissions } from '@/modules/score-output/score-output-permissions';
import { i18n } from '@/i18n';

const { fields } = ScoreOutputModel;

export default {
  name: 'app-score-output-list-table',

  mounted() {
    this.doMountTable(this.$refs.table);
  },

  computed: {
    ...mapGetters({
      rows: 'scoreOutput/list/rows',
      count: 'scoreOutput/list/count',
      loading: 'scoreOutput/list/loading',
      saveLoading: 'scoreOutput/list/saveLoading',
      pagination: 'scoreOutput/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      hasAdminRole: 'auth/hasAdminRole',
      destroyLoading: 'scoreOutput/destroy/loading',
      paginationLayout: 'layout/paginationLayout',
    }),

    hasPermissionToEdit() {
      return new ScoreOutputPermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new ScoreOutputPermissions(this.currentUser).destroy;
    },

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'scoreOutput/list/doChangeSort',
      doChangePaginationCurrentPage:
        'scoreOutput/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize:
        'scoreOutput/list/doChangePaginationPageSize',
      doMountTable: 'scoreOutput/list/doMountTable',
      doDestroy: 'scoreOutput/destroy/doDestroy',
    }),

    presenter(row, fieldName) {
      return ScoreOutputModel.presenter(row, fieldName);
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        return this.doDestroy(id);
      } catch (error) {
        // no
      }
    },
  },
};
</script>

<style></style>
