<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
          code="entities.scoreOutput.menu"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.scoreOutput.list.title"
        ></app-i18n>
      </h1>

      <app-score-output-list-toolbar></app-score-output-list-toolbar>
      <app-score-output-list-filter></app-score-output-list-filter>
      <app-score-output-list-table></app-score-output-list-table>
    </div>
  </div>
</template>

<script>
import ScoreOutputListFilter from '@/modules/score-output/components/score-output-list-filter.vue';
import ScoreOutputListTable from '@/modules/score-output/components/score-output-list-table.vue';
import ScoreOutputListToolbar from '@/modules/score-output/components/score-output-list-toolbar.vue';
import {mapMutations} from "vuex";

export default {
  name: 'app-score-output-list-page',

  components: {
    [ScoreOutputListFilter.name]: ScoreOutputListFilter,
    [ScoreOutputListTable.name]: ScoreOutputListTable,
    [ScoreOutputListToolbar.name]: ScoreOutputListToolbar,
  },

  mounted() {
    this.setSaveLoading(false);

  },
  methods:{
    ...mapMutations({
      setSaveLoading: 'scoreOutput/list/SET_SAVE_LOADING',
    }),
  }
};
</script>

<style></style>
